import { render, staticRenderFns } from "./myGiftDetail.vue?vue&type=template&id=071d8dbb&"
import script from "./myGiftDetail.vue?vue&type=script&lang=js&"
export * from "./myGiftDetail.vue?vue&type=script&lang=js&"
import style0 from "./myGiftDetail.vue?vue&type=style&index=0&id=071d8dbb&prod&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports