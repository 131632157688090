<template>
    <div class="page_mygift iphonex-pb" v-if="showPage" >
        <headeBar title="我的礼品卡" left-arrow @click-left="newAppBack" />
        <refresh ref="content" @onRefresh="onRefresh" class="content">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="loadList"
                :immediate-check="false"
                :offset="50"
                :error="!hasdata"
                finished-text="没有更多了"
                class="pdb"
            >
                <div class="cardheader">
                    <div class="tit-int">总余额</div>
                    <div class="money-int">
                        ¥ <span class="money-value">{{ myCount[0] }}</span
                        >.{{ myCount[1] }}
                    </div>
                    <div class="optline van-hairline--top">
                        <div
                            class="optflex van-hairline--right"
                            @click="exchange"
                        >
                            <img
                                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/giftcards/add.png"
                                class="optlogo"
                            />
                            <div class="optlabel">兑换新卡</div>
                            <van-icon
                                name="arrow"
                                color="#6C727A"
                                class="arrow"
                            />
                        </div>
                        <!-- <div class="centerline"></div> -->
                        <div class="optflex" @click="goStore">
                            <img
                                src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/giftcards/shop.png"
                                class="optlogo"
                            />
                            <div class="optlabel">购买礼品卡</div>
                            <van-icon
                                name="arrow"
                                color="#6C727A"
                                class="arrow"
                            />
                        </div>
                    </div>
                </div>
                <template v-if="hasdata">
                    <div
                        class="gi-card"
                        v-for="item in list"
                        :key="item.cardId"
                        @click="handlerGiftDetais(item.cardId)"
                    >
                        <div class="coner" v-if="item.givingNow == 1">
                            赠送中
                        </div>
                        <div class="cardImg">
                            <img :src="item.img" v-if="item.img" />
                        </div>

                        <div class="card-details">
                            <div class="detail-left">
                                <div class="cardprice">
                                    <div class="def-int">
                                        ¥
                                        <span class="def-money-int">{{
                                            tofx2(item.acountNow)
                                        }}</span>
                                    </div>
                                </div>
                                <div class="youxiaoqi">{{ item.endTime }}</div>
                            </div>
                            <div class="detail-right">
                                <div
                                    v-if="item.hasCanSuperpose"
                                    @click.stop="getUsedList(item.cardId)"
                                    class="hck"
                                >
                                    <span class="tip-mutual">互斥卡</span>
                                    <van-icon
                                        class="ic"
                                        color="#ED5C42"
                                        name="question-o"
                                    />
                                </div>

                                <div class="f2">
                                    {{ item.disableRemarks }}
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <emptyPage
                    v-else
                    class="empty"
                    text="暂无可用礼品卡<br/>快去买一张吧"
                />
            </van-list>
        </refresh>
        <div class="bottom-buy iphonex-pb" >
            <div @click="jumpQuestion" >
                <span class="buy-sty">礼品卡常见问题</span>
                <van-icon name="arrow" color="#9AA1A9" class="but-icon" />
            </div>
            <div class="shu"></div>
            <div @click="toIVD" >
                <span class="buy-sty">查看已失效卡</span>
                <van-icon name="arrow" color="#9AA1A9" class="but-icon" />
            </div>
        </div>
        <instructions ref="instructions" />
    </div>
</template>

<script>
import { newAppBack,initBack, gotoAppPage,defaultApp } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import emptyPage from '@/components/app/emptyPage';
import instructions from '@/components/app/instructions';
import refresh from '@/components/app/refreshTemp';
import userMixin from '@/mixin/userMixin';
export default {
    components: {
        headeBar,
        emptyPage,
        instructions,
        refresh,
    },
    mixins: [userMixin],
    data() {
        return {
            list: [],
            pageNum: 1,
            finished: false,
            loading: false,
            hasdata: true,
            myCount: ['0', '00'],
            isRemain: 1,
            accountSum: '--',
            showPage:true
        };
    },
    methods: {
        newAppBack,
        tofx2(val){
            return isNaN(val)?'--':parseFloat(val).toFixed(2);
        },
        toIVD() {
            this.$router.push('/myGift/invalid');
        },
        exchange() {
            gotoAppPage(4);
        },
        getMyAllCount() {
            this.$axios
                .post(
                    this.baseURLApp + '/cdKey/getMyGiftCardForAvailableAcount',
                    {
                        userId: this.userId,
                        token: this.token,
                    }
                )
                .then((res) => {
                    const myCount = res.data.acountSum.split('.');
                    if (myCount.length == 1) {
                        myCount.push('00');
                    }
                    this.myCount = myCount;
                    this.isRemain = res.data.isRemain === '1' ? 1 : 0;
                    this.accountSum = res.data.sendAccountRemainSum;
                });
        },
        getData(refresh) {
            const rows = 5;
            return this.$axios
                .post(this.baseURLApp + '/cdKey/getMyGiftCardForAvailable', {
                    rows,
                    userId: this.userId,
                    token: this.token,
                    page: this.pageNum,
                })
                .then((res) => {
                    if (res.code == '1') {
                        const data = res.data;
                        if (
                            // true
                            (!data || data.length == 0) &&
                            this.list.length == 0
                        ) {
                            this.hasdata = false;
                            this.list = [];
                        } else {
                            this.hasdata = true;
                            if (refresh) {
                                this.list = data;
                            } else {
                                this.list.push(...data);
                            }

                            if (data.length < rows) {
                                this.finished = true;
                                console.log('fs');
                            } //全部加载完了
                            else {
                                this.finished = false;
                            }
                        }
                    } else {
                        this.$toast('网络开小差了');
                        throw new Error();
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                    this.$toast('网络开小差了');
                });
        },
        jumpQuestion() {
            this.$router.push(
                '/common-article?isBackToH5=true&&key=771029689006292992'
            );
        },
        loadList() {
            this.pageNum += 1;
            this.getData();
        },
        onRefresh(done) {
            this.getMyAllCount();
            this.reset(done);
        },
        reset(done) {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType:'spinner'
            });
            this.pageNum = 1;
            this.finished = false;
            this.loading = true;
            this.getData(true).then(() => {
                this.$toast.clear();
                setTimeout(() => {
                    done && done();
                }, 500);
            });
        },
        handlerGiftDetais(id) {
            this.$router.push('/myGift/detail?cardId=' + id);
        },
        goStore() {
            gotoAppPage(5);
        },
        getUsedList(id) {
            this.$refs.instructions.getUsedList(id);
        },
        setScroll() {
            const dom = this.$refs['content'];
            if (dom && this.scrolltop) {
                this.$nextTick(() => {
                    dom.$el.scrollTop = this.scrolltop || 0;
                });
            }
        },
        onShow(){
            if (document.visibilityState === "visible"&&this.$route.name=="myGift/index") {
                initBack();
                this.onRefresh();
            }
        }
    },
    async created() {
        initBack();

        //由于该页面会跳转别的app原生页后退回到本页面避免劫持失效再次initback
        document.addEventListener("visibilitychange", this.onShow);
        await this.$getUserId();
        if (this.userId && this.token) {
            this.getMyAllCount();
            this.getData();
            this.$EventBus.$on('mygift_refreshDataById', (data) => {
                //更新赠送状态及金额
                if (data.cardId) {
                    const index = this.list.findIndex((d) => {
                        return data.cardId == d.cardId;
                    });
                    if (index > -1) {
                        this.$set(this.list, index, {
                            ...this.list[index],
                            acountNow: data.acountNow,
                            givingNow: data.giveStatus == 3 ? 1 : 0,
                        });
                    }
                }
            });
            this.$EventBus.$on('mygift_refreshData', this.onRefresh);

        } else {
            this.showPage=false;
            defaultApp();
            this.hasdata = false;
            const query=this.$route.query;
            this.$toast( '缺少参数'+JSON.stringify(query));
        }
    },
    beforeRouteLeave(to, from, next) {
        this.scrolltop = this.$refs['content'].$el.scrollTop;
        next();
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.setScroll();
        });
    },
};
</script>

<style lang="less" >
.page_mygift {
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;

    .fullflex {
        flex: 1;
    }
    .empty {
        height: calc(100vh - 620px);
    }
    .content {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        background: #f5f5f5;
        .van-pull-refresh__track::before {
            content: '';
            height: 500px;
            background: #fff;
            position: absolute;
            bottom: 100%;
            left: 0;
            width: 100%;
        }
        .pdb {
            padding-bottom: 84px;
        }
        .cardheader {
            background: #fff;
            padding-top: 36px;
            text-align: center;
            color: #242831;
            .tit-int {
                font-size: 24px;
                font-weight: bold;
            }
            .money-int {
                margin: 20px 0 0;
                font-family: BebasNeueBold;
                font-weight: bold;
                font-size: 48px;
                font-style: inherit;
                .money-value {
                    font-size: 80px;
                    font-weight: normal;
                }
            }
            .money-send {
                color: #6c727a;
                font-size: 22px;
                margin: 10px 0 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .qs {
                    margin-left: 4px;
                    font-size: 26px;
                }
            }
            .optline {
                margin-top: 32px;
                height: 100px;
                box-sizing: border-box;
                display: flex;
                position: relative;
                .optflex {
                    flex: 1;
                    display: flex;
                    align-items: center;
                    padding: 0 32px;
                    .optlogo {
                        width: 36px;
                        height: 36px;
                    }
                    .optlabel {
                        flex: 1;
                        text-align: left;
                        font-weight: bold;
                        font-size: 26px;
                        margin-left: 16px;
                        // line-height: 1;
                        overflow: visible;
                    }
                    .arrow {
                        font-size: 28px;
                        font-weight: bold;
                    }
                }
                .centerline {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 50%;
                    width: 1px;
                    background: #eee;
                    transform: translateX(-50%);
                }
            }
        }
        .gi-card {
            background-color: #fff;
            border-radius: 16px;
            margin: 32px 32px 0;
            position: relative;
            // overflow: hidden;
            .coner {
                position: absolute;
                right: 24px;
                top: 24px;
                background: #242831;
                text-align: center;
                color: #fff;
                width: 84px;
                line-height: 40px;
                font-size: 20px;
                border-radius: 4px;
                font-weight: bold;
            }
            .cardImg {
                height: 412px;
                // background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/a1eccf67-40f6-4c5e-9d58-4bbbab1e6983.jpg');
                background: #eee;
                background-size: cover;
                border-radius: 16px;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            /* 详细说明 */
            .card-details {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 20px 32px;
                .detail-left {
                    font-size: 44px;
                    .cardprice {
                        font-weight: bold;
                        height: 60px;
                        display: flex;
                        align-items: center;
                    }
                    .def-int {
                        line-height: 60px;
                        height: 48px;
                        font-family: BebasNeueBold;
                        font-weight: bold;
                        font-size: 32px;
                        font-style: inherit;
                        .def-money-int {
                            font-size: 48px;
                            font-weight: normal;
                        }
                    }
                    .youxiaoqi {
                        font-size: 18px;
                        color: #6c727a;
                    }
                }
                .detail-right {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;

                    .hck {
                        display: flex;
                        align-items: center;
                        height: 60px;
                        .tip-mutual {
                            color: #ed5c42;
                            border: 1px solid #ed5c42;
                            border-radius: 4px;
                            font-size: 18px;
                            font-weight: bold;
                            margin: 0 10px;
                            padding: 0 6px;
                            height: 28px;
                            display: flex;
                            align-items: center;
                        }
                        .ic {
                            font-size: 28px;
                        }
                    }

                    .f2 {
                        font-size: 18px;
                        color: #6c727a;
                    }
                }
            }
        }
    }
}

.bottom-buy {
    position: fixed;
    background: #f5f5f5;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: center;
    z-index: 999;
    align-items: center;
    color: #9aa1a9;
    .buy-sty {
        font-size: 24px;
        line-height: 1;
        overflow: visible;
    }
    .but-icon {
        margin-left: 4px;
        font-size: 24px;
    }
    .shu{
        height: 24px;
        width: 1px;
        margin: 0 24px;
        background: #E3E5E8;
    }
}
</style>
