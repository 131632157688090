<template>
    <div class="page_myInvalidGift iphonex-pb">
        <headeBar title="已失效礼品卡" left-arrow @click-left="newAppBack" />
        <div class="plabar">
            <div class="pd secbar">
                <div
                    class="baritem"
                    v-for="(item, key) in datajson"
                    :key="key"
                    @click="setbar(key)"
                    :class="{ active: key === activeKey }"
                >
                    {{ item.name + getCount(item) }}
                </div>
            </div>
        </div>

        <refresh ref="content" @onRefresh="onRefresh" class="content">
            <div
                v-for="(pitem, key) in datajson"
                :key="key"
                v-show="key === activeKey"
                class="cardlist"
            >
                <template v-if="pitem.hasdata">
                    <div
                        class="gi-card"
                        v-for="item in pitem.list"
                        :key="item.cardId"
                        @click="handlerGiftDetais(item.cardId, key)"
                    >
                        <div class="cardImg">
                            <img :src="item.img" v-if="item.img" />
                        </div>

                        <div class="card-details">
                            <div class="detail-left">
                                <div class="cardprice">
                                    <div class="def-int">
                                        ¥
                                        <span class="def-money-int">{{
                                            item.acountNow
                                        }}</span>
                                    </div>
                                </div>
                                <div class="youxiaoqi">{{ item.endTime }}</div>
                            </div>
                            <div class="detail-right">
                                <div
                                    v-if="item.hasCanSuperpose"
                                    @click.stop="getUsedList(item.cardId)"
                                    class="hck"
                                >
                                    <span class="tip-mutual">互斥卡</span>
                                    <van-icon
                                        class="ic"
                                        color="#ED5C42"
                                        name="question-o"
                                    />
                                </div>

                                <div class="f2">
                                    {{ item.disableRemarks }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="t"></div>
                </template>
                <emptyPage v-else style="height: 100%" text="暂无失效礼品卡" />
            </div>
        </refresh>

        <div class="ipxbom iphonex-pb"></div>
    </div>
</template>

<script>
import { newAppBack,initBack } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import emptyPage from '@/components/app/emptyPage';
import refresh from '@/components/app/refreshTemp';
import userMixin from '@/mixin/userMixin';
export default {
    components: {
        headeBar,
        emptyPage,
        refresh,
    },
    mixins: [userMixin],
    data() {
        return {
            datajson: {
                runOutOf: {
                    name: '已用完',
                    list: [],
                    hasdata: true,
                },
                due: {
                    name: '已过期',
                    list: [],
                    hasdata: true,
                },
                given: {
                    name: '已转赠',
                    list: [],
                    hasdata: true,
                },
                frozen: {
                    name: '已冻结',
                    list: [],
                    hasdata: true,
                },
            },
            activeKey: 'runOutOf',
        };
    },
    methods: {
        newAppBack,
        setbar(key) {
            this.activeKey = key;
            this.$refs.content.$el.scrollTop = 0;
        },
        onRefresh(done) {
            this.getData().then(() => {
                setTimeout(() => {
                    done();
                }, 500);
            });
        },
        getCount(item) {
            if (item.list.length > 0) {
                return ' ' + item.list.length;
            } else {
                return '';
            }
        },
        back() {
            this.$router.go(-1);
        },
        getData() {
            return this.$axios
                .post(this.baseURLApp + '/cdKey/getMyGiftCardForLoseEfficacy', {
                    userId: this.userId,
                    token: this.token,
                })
                .then((res) => {
                    const data = res.data;
                    console.log(data);
                    this.datajson.due.list = data.dueList;
                    this.datajson.due.hasdata = data.dueList.length > 0;

                    this.datajson.frozen.list = data.frozenList;
                    this.datajson.frozen.hasdata = data.frozenList.length > 0;

                    this.datajson.given.list = data.givenList;
                    this.datajson.given.hasdata = data.givenList.length > 0;

                    this.datajson.runOutOf.list = data.runOutOfList;
                    this.datajson.runOutOf.hasdata =
                        data.runOutOfList.length > 0;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handlerGiftDetais(id, key) {
            if (key == 'given') {
                //转赠
                this.$router.push('/myGift/detail?issend=1&cardId=' + id);
            } else {
                this.$router.push('/myGift/detail?cardId=' + id);
            }
        },
    },
    async created() {
        initBack();
        await this.$getUserId();
        if (this.userId && this.token) {
            this.getData();
        } else {
            this.hasdata = false;
        }
    },
};
</script>

<style lang="less">
.t {
    height: 1px;
    margin-top: -1px;
}
.page_myInvalidGift {
    background: #f5f5f5;
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    .content {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }
    .pd {
        padding: 0 30px;
    }
    .plabar {
        height: 108px;
    }
    .secbar {
        position: fixed;
        left: 0;
        width: 100%;
        display: flex;
        z-index: 10;
        box-sizing: border-box;
        padding-top: 24px;
        padding-bottom: 24px;
        // background: rgba(245, 245, 245, 0.7);
        background: #f5f5f5;
        z-index: 5;
        .baritem {
            padding: 0 24px;
            margin-right: 16px;
            text-align: center;
            background: #fff;
            border-radius: 6px;
            height: 60px;
            line-height: 60px;
            cursor: pointer;
            font-size: 24px;
            color: #6c727a;
            box-sizing: border-box;
        }
        .active {
            border: 1px solid #242831;
            color: #242831;
            font-weight: bold;
        }
    }
    .cardlist {
        height: 100%;
        box-sizing: border-box;
    }
    .gi-card {
        background-color: #fff;
        border-radius: 16px;
        margin: 0 32px 32px;
        position: relative;
        overflow: hidden;
        color: #6c727a;
        .cardImg {
            height: 412px;
            // background: url('https://chaolucoachwebstatic.chaolu.com.cn/static/img/wxXiaoChengXu/giftCardStore/a1eccf67-40f6-4c5e-9d58-4bbbab1e6983.jpg');
            background: #eee;
            background-size: cover;
            border-radius: 16px;
            overflow: hidden;
            img {
                width: 100%;
                height: 100%;
                filter: grayscale(100%);
            }
        }
        /* 详细说明 */
        .card-details {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 20px 32px;
            .detail-left {
                font-size: 44px;
                .cardprice {
                    height: 60px;
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    .def-int {
                        line-height: 60px;
                        height: 48px;
                        font-family: BebasNeueBold;
                        font-size: 32px;
                        color: #6c727a;
                        .def-money-int {
                            font-size: 48px;
                            color: #6c727a;
                            font-weight: normal;
                        }
                    }
                }

                .youxiaoqi {
                    font-size: 18px;
                    color: #6c727a;
                }
            }
            .detail-right {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;

                .hck {
                    display: flex;
                    align-items: center;
                    height: 60px;
                    .tip-mutual {
                        color: #6c727a;
                        border: 1px solid #6c727a;
                        border-radius: 4px;
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0 10px;
                        padding: 0 6px;
                        height: 28px;
                        display: flex;
                        align-items: center;
                    }
                    .ic {
                        font-size: 28px;
                    }
                }

                .f2 {
                    font-size: 18px;
                    color: #6c727a;
                }
            }
        }
    }
}
</style>
