<template>
    <div class="page_myGiftDetail iphonex-pb" v-if="cardId">
        <headeBar title="礼品卡" left-arrow @click-left="newAppBack" />
        <refresh ref="content" @onRefresh="onRefresh" class="content">
            <van-list
                v-model="loading"
                :finished="finished"
                @load="loadList"
                :immediate-check="false"
                :offset="50"
                finished-text=""
                class="card"
            >
                <div class="cardimg">
                    <img
                        :src="
                            cardInfo.img ||
                            'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/null2.png'
                        "
                    />
                    <div class="cardinfo">
                        <div class="cardnumber">{{ cardId }}</div>
                        <div class="userange" @click="jumpQuestion">
                            <div class="rt">使用范围</div>
                            <van-icon name="question-o" class="qsicon" />
                        </div>
                    </div>
                </div>
                <div v-if="issend" class="sendbox">
                    <div class="tips1">状态</div>
                    <div class="tips2">
                        {{ cardInfo.giveDescribe }}
                    </div>
                    <div class="tips1">{{ cardInfo.endTime }}</div>
                </div>
                <template v-else>
                    <div
                        class="sendline"
                        v-if="cardInfo.giveStatus == '2'"
                        @click="getSendInfo"
                    >
                        {{ cardInfo.giveDescribe }}
                        <van-icon
                            name="arrow"
                            color="#242831"
                            class="sendarrow"
                        />
                    </div>
                    <div class="carddetail">
                        <div class="fullflex">
                            <div class="cardlabel">余额</div>
                            <div class="cardvalue">
                                <span class="mn">¥</span>
                                <span class="ye">{{ tofx2(cardInfo.acountNow) }}</span>
                            </div>
                            <!-- <div class="cardlabel" v-if="cardInfo.isRemain&&cardInfo.sendAccountRemain">
                                含赠送¥{{ tofx2(cardInfo.sendAccountRemain) }}
                            </div> -->
                        </div>
                        <div class="fullflex">
                            <div class="cardlabel">状态</div>
                            <div class="cardvalue cardstatus">
                                {{ cardInfo.statusDescribe }}
                            </div>
                            <div
                                class="cardlabel"
                                style="color: #267dff"
                                v-if="cardInfo.giveStatus == '3'"
                                @click="cancel"
                            >
                                取消
                            </div>
                        </div>
                    </div>
                    <div class="uselist">
                        <div class="useheader">
                            <div class="useheader_left">使用记录</div>
                            <div class="useheader_right">
                                {{ cardInfo.endTime }}
                            </div>
                        </div>
                        <template v-if="hasdata">
                            <div
                                class="listmodel van-hairline--bottom"
                                v-for="item in list"
                                :key="item.payOrderNo"
                                @click="toOrder(item)"
                            >
                                <div class="typeimg">
                                    <img :src="geturl(item.extraType)" />
                                </div>
                                <div class="fullflex listinfo ellipsis">
                                    <div class="listtitle ellipsis">
                                        {{ item.subject }}
                                    </div>
                                    <div class="listtips">
                                        <span>{{ item.extra }}</span>
                                        <span class="usetime" >{{ item.useTime }}</span>
                                    </div>
                                </div>
                                <div
                                    class="listvalue"
                                    :class="{ red: ['1', '4'].includes(item.useSub) }"
                                >
                                    {{
                                        ( ['1', '4'].includes(item.useSub) ? '- ' : '+ ') + tofx2(item.useAmount)
                                    }}
                                </div>
                                <van-icon
                                    name="arrow"
                                    color="#6C727A"
                                    class="arrow"
                                />
                            </div>
                        </template>
                        <div v-else class="empty">
                            <emptyPage text="这是一张崭新的卡" />
                            <div
                                v-if="
                                    cardInfo.giveStatus == '1' ||
                                    cardInfo.giveStatus == '3'
                                "
                                class="send"
                                @click="send"
                            >
                                转赠给好友
                            </div>
                        </div>
                    </div>
                </template>
            </van-list>
        </refresh>
        <instructions ref="instructions" />
        <van-popup
            v-model="show"
            :overlay-style="{ backdropFilter: 'blur(10px)' }"
            class="givenpop"
        >
            <van-icon name="close" class="popclose" @click="show=false" />
            <div class="poptitle" >{{giftInfo.givingDescribe}}</div>
            <div class="popcard">
                <img class="popcardimg" :src="giftInfo.img" />
                <div class="userline">
                    <div class="userhead">
                        <img :src="giftInfo.givingUserImg" v-if="giftInfo.givingUserImg" />
                    </div>
                    <div class="username ellipsis" >{{giftInfo.givingUserName}}</div>
                </div>
                <div class="popremark">
                    {{giftInfo.note||'啥都没说'}}
                </div>
            </div>
            <div class="poptime" >{{giftInfo.timeDescribe}}</div>
        </van-popup>
    </div>
</template>

<script>
import { gotoAppPage, appType,newAppBack,initBack,defaultApp } from '@/lib/appMethod';
import headeBar from '@/components/app/headBar';
import instructions from '@/components/app/instructions';
import emptyPage from '@/components/app/emptyPage';
import refresh from '@/components/app/refreshTemp';
import userMixin from '@/mixin/userMixin';
export default {
    components: {
        headeBar,
        emptyPage,
        instructions,
        refresh,
    },
    mixins: [userMixin],
    data() {
        return {
            appType: appType(),
            hasdata: true,
            finished: false,
            loading: false,
            pageNum: 1,
            list: [],
            giftInfo:{},
            cardInfo: {
                isRemain: '--',
                statusDescribe: '--',
                // giveStatus（0：不可转增；1：可转赠；2：获赠或已赠；3：赠送中）
            },
            show: false,
            issend: false,
        };
    },
    methods: {
        newAppBack,
        tofx2(val){
            return isNaN(val)?'--':parseFloat(val).toFixed(2);
        },
        cancel() {
            this.$dialog
                .confirm({
                    title: '提示',
                    message: '取消赠送后，好友将无法领取该礼品卡',
                    cancelButtonText: '我再想想',
                    confirmButtonText: '确定',
                    className:'cancelsend'
                })
                .then(() => {
                    this.$axios
                        .post(this.baseURLApp + '/giving/cancelGiveCard', {
                            userId: this.userId,
                            token: this.token,
                            cardId: this.cardId,
                        })
                        .then((res) => {
                            this.getCardDetail();
                        });
                })
                .catch(() => {
                    // on cancel
                });
        },
        toOrder(item) {
            gotoAppPage(7, { payOrderNo: item.payOrderNo });
        },
        send() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                className:'fb',
                loadingType:'spinner'
            });
            gotoAppPage(6, { cardId: this.cardId });
        },
        getSendInfo() {
            this.$axios
                .post(this.baseURLApp + '/cdKey/getCardGivingDetail', {
                    userId: this.userId,
                    token: this.token,
                    cardId: this.cardId,
                })
                .then((res) => {
                    console.log(res);
                    this.giftInfo=res.data;
                    this.show = true;
                });
        },
        getData(refresh) {
            if (this.issend) {
                return;
            }
            const rows = 20;
            return this.$axios
                .post(this.baseURLApp + '/cdKey/getCardPayFlow', {
                    userId: this.userId,
                    token: this.token,
                    cardId: this.cardId,
                    page: this.pageNum,
                    rows,
                })
                .then((res) => {
                    if (res.code == '1') {
                        const data = res.data;
                        if (
                            (!data || data.length == 0) &&
                            this.list.length == 0
                        ) {
                            this.hasdata = false;
                            this.finished = true;
                            this.list = [];
                        } else {
                            this.hasdata = true;
                            if (refresh) {
                                this.list = data;
                            } else {
                                this.list.push(...data);
                            }

                            if (data.length < rows) {
                                this.finished = true;
                                console.log('fs');
                            } //全部加载完了
                            else {
                                this.finished = false;
                            }
                        }
                    } else {
                        this.$toast('状态有误');
                        throw new Error();
                    }
                    this.loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.$toast('网络开小差了');
                });
        },
        loadList() {
            this.pageNum += 1;
            this.getData();
        },
        onRefresh(done) {
            if (this.issend) {
                this.getGiveCardDetail().then(() => {
                    setTimeout(() => {
                        done();
                    }, 500);
                });
            } else {
                this.getCardDetail();
                this.reset(done);
            }
        },
        reset(done) {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType:'spinner'
            });
            this.pageNum = 1;
            this.finished = false;
            this.loading = true;
            this.getData(true).then(() => {
                this.$toast.clear();
                setTimeout(() => {
                    done && done();
                }, 500);
            });
        },
        getCardDetail() {
            if (this.issend) {
                return Promise.reject();
            }
            return this.$axios
                .post(this.baseURLApp + '/cdKey/getMyCardDetail', {
                    userId: this.userId,
                    token: this.token,
                    cardId: this.cardId,
                })
                .then((res) => {
                    if (res.data && res.data.cardId) {
                        this.cardInfo = res.data;
                        this.$EventBus.$emit('mygift_refreshDataById',this.cardInfo);
                    }
                    console.log(res);
                }).catch(()=>{
                    this.$EventBus.$emit('mygift_refreshData');
                    this.$router.go(-1);
                }).then(()=>{
                    this.$toast.clear();
                });
        },
        getGiveCardDetail() {
            if(!this.issend){return}
            return this.$axios
                .post(this.baseURLApp + '/cdKey/getMyGivenCardDetail', {
                    userId: this.userId,
                    token: this.token,
                    cardId: this.cardId,
                })
                .then((res) => {
                    if (res.data && res.data.cardId) {
                        this.cardInfo = res.data;
                    }
                    console.log(res);
                })
                .catch(() => {});
        },
        jumpQuestion() {
            this.$refs.instructions.getUsedList(this.cardId);
        },
        geturl(type) {
            switch (type) {
                case '1':
                case '8': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_class.png';
                }
                case '2': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_zhizhu.png';
                }
                case '3': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_vip.png';
                }
                case '4': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_mini.png';
                }
                case '5': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_cz.png';
                }
                case '10':
                case '11': {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/type_xly.png';
                }
                default: {
                    return 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/null.png';
                }
            }
        },
        onShow(){
            if (document.visibilityState === "visible") {
                this.getCardDetail();
                initBack();
            }
        }
    },
    async created() {
        this.cardId = this.$route.query.cardId;
        if (this.cardId) {
            await this.$getUserId();
            if (this.userId && this.token) {
                if (this.$route.query.issend) {
                    this.issend = true;
                    this.getGiveCardDetail();
                } else {
                    this.issend = false;
                    this.getCardDetail();
                    this.getData();
                    document.addEventListener("visibilitychange", this.onShow)
                }
            } else {
                this.hasdata = false;
            }
        }else{
            defaultApp();
        }
    },
    beforeDestroy(){
        document.removeEventListener("visibilitychange", this.onShow)
    }
};
</script>

<style lang="less" >
.cancelsend{
    border-radius: 12px;
    .van-dialog__header{
        font-size: 36px;font-weight: bold;color: #242831;padding-top: 62px;
    }
    .van-dialog__message{
        color: #9AA1A9;font-size: 28px;
    }
    .van-button--default{color: #9AA1A9;font-size: 32px;}
    .van-dialog__confirm, .van-dialog__confirm:active{color: #FF6E00;}
}
.fb{
    width: 100%;max-width: 100%;height: 100%;max-height: 100%;backdrop-filter: blur(20px)
}
.page_myGiftDetail {
    background: #fff;
    display: flex;
    height: 100vh;
    box-sizing: border-box;
    flex-direction: column;
    .fullflex {
        flex: 1;
        text-align: center;
    }
    .content {
        flex: 1;
        overflow: auto;
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
    }
    .card {
        padding-top: 32px;
        .cardimg {
            background: #f7f7f7;
            margin: 0 32px 24px;
            height: 394px;
            border-radius: 16px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: 100%;
            }
            .cardinfo {
                box-sizing: border-box;
                position: absolute;
                left: 0;
                width: 100%;
                bottom: 0;
                color: #fff;
                padding: 32px 32px 26px;
                display: flex;
                justify-content: space-between;
                line-height: 32px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
                .cardnumber {
                    font-size: 32px;
                }
                .userange {
                    display: flex;
                    align-items: center;
                    font-size: 24px;
                    .qsicon {
                        font-size: 28px;
                        margin-left: 4px;
                    }
                }
            }
        }
        .sendbox {
            text-align: center;
            padding-top: 48px;
            .tips1 {
                font-size: 24px;
                color: #6c727a;
            }
            .tips2 {
                font-size: 32px;
                color: #242831;
                margin: 20px 0;
            }
        }
        .sendline {
            color: #242831;
            font-size: 24px;
            text-align: center;
        }
        .carddetail {
            height: 170px;
            padding: 24px 0 0;
            margin: 0 30px;
            display: flex;
            .cardlabel {
                font-size: 24px;
                color: #6c727a;
            }
            .cardvalue {
                color: #242831;
                font-size: 48px;
                font-family: BebasNeueBold;
                font-weight: bold;
                line-height: 58px;
                margin: 14px 0 0px;
                .mn{font-size: 36px;margin-right: 6px;}
                .ye{font-weight: normal;}
            }
            .cardstatus {
                font-size: 32px;
                font-family: inherit;
            }
        }
        .uselist {
            margin: 0 32px;
            .useheader {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
                .useheader_left {
                    font-size: 32px;
                    color: #000;
                    font-weight: bold;
                }
                .useheader_right {
                    font-size: 24px;
                    color: #6c727a;
                }
            }
            .listmodel {
                display: flex;
                align-items: center;
                padding: 24px 0;
                .typeimg {
                    width: 96px;
                    height: 96px;
                    overflow: hidden;
                    background: #d8d8d8;
                    border-radius: 50%;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .listinfo {
                    margin: 0 0 0 24px;
                    text-align: left;
                    .listtitle {
                        position: relative;
                        box-sizing: border-box;
                        font-size: 28px;
                        color: #242831;
                    }
                    .listtips {
                        font-size: 24px;
                        color: #6c727a;
                        margin-top: 8px;
                        .usetime{
                            margin-left: 10px;
                        }
                    }
                }

                .listvalue {
                    color: #242831;
                    font-size: 28px;
                    margin-right: 20px;
                    width: 120px;
                    font-weight: bold;
                    text-align: right;
                }
                .red {
                    color: #ed5c42;
                }
                &:last-of-type::after {
                    border: none;
                }
                .arrow {
                    font-weight: bold;
                    font-size: 28px;
                }
            }
        }
        .empty {
            padding: 120px 0;
            .send {
                width: 240px;
                line-height: 88px;
                color: #242831;
                background: #ffde00;
                border-radius: 8px;
                text-align: center;
                margin: 32px auto;
                font-size: 28px;
                font-weight: bold;
            }
        }
    }
    .givenpop{
        text-align: center;
        background: #ffffff00;
        padding: 70px 50px;
        .popclose{
            font-size: 60px;
            color: #fff;
            position: absolute;
            top: 0;
            right: 0;
            cursor: pointer;
        }
        .poptitle{
            font-size: 40px;
            font-weight: bold;
            color: #fff;
        }
        .popcard{
            width: 558px;
            border-radius: 14px;
            overflow: hidden;
            background: #fff;
            margin: 48px 0;
            .popcardimg{
                height: 334px;
                background: #eee;
                border-radius: 14px;
                width: 100%;
                vertical-align: top;
            }
            .userline{
                display: flex;
                padding: 0 24px;
                box-sizing: border-box;
                margin-top: -64px;
                align-items: flex-end;
                .userhead{
                    background: #eee;
                    width: 128px;
                    height: 128px;
                    border-radius: 50%;
                    border: 4px solid #fff;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .username{
                    text-align: left;
                    flex: 1;
                    font-size: 28px;
                    color: #242831;
                    font-weight: bold;
                    margin-bottom: 16px;
                    margin-left: 16px;
                }
            }
            .popremark{
                margin: 24px;
                padding: 22px 24px;
                background: #FFF8CC;
                color: #A69000;
                font-size: 24px;
                font-weight: bold;
                position: relative;
                text-align: left;
                &::before{
                    content: '';
                    position: absolute;
                    left: 62px;
                    top: 0;
                    width: 12px;
                    height: 12px;
                    border-radius: 5px;
                    transform-origin: 50% 50%;
                    margin-top: -6px;
                    transform: rotate(45deg);
                    background: inherit;
                }
            }
        }
        .poptime{
            font-size: 24px;
            color: #fff;
        }
    }
}
</style>
