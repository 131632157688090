<!--
    <refresh ref="content" @onRefresh="onRefresh" class="content"></refresh>
    onRefresh(done) {
        done();
    }
-->
<template>
    <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        pulling-text="继续往下拉"
        loosing-text="该放手了，我要刷新啦..."
        loading-text="拼命加载中"
        :pull-distance="100"
        head-height="70"
        ref="refresh"
    >
        <template #normal>
            <img class="doge" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/refresh.png" />
            <div class="refreshfont">继续往下拉</div>
        </template>
        <template #pulling="props">
            <img class="doge" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/refresh.png"  />
            <div class="refreshfont">继续往下拉</div>
        </template>
        <template #loosing>
            <img class="doge" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/refresh.png" />
            <div class="refreshfont">
                该放手了，我要刷新啦...
            </div>
        </template>
        <template #loading>
            <img class="doge ani" src="https://chaolucoachwebstatic.chaolu.com.cn/static/img/miniprogram/uniapp/refresh.png"  />
            <div class="refreshfont">拼命加载中</div>
        </template>
        <slot />
    </van-pull-refresh>
</template>

<script>
export default {
    data(){
        return{
            refreshing:false
        }
    },
    methods:{
        onRefresh(){
            this.$emit('onRefresh',this.refreshDone)
        },
        refreshDone(){
            this.refreshing=false;
        }
    }
};
</script>

<style>
.refreshfont{
    text-align: center; line-height: 20PX;font-size: 12PX;
}
.doge{
    width: 50PX;
    height: 50PX;
    vertical-align: top;
    padding: 0;
    margin: 0;
}
.ani{
    animation: myfirst 1s linear infinite;
}
@keyframes myfirst
{
    0%   {transform: translate3d(0,0,0);}
    25%  {transform: translate3d(-10px,0,0);}
    50%  {transform: translate3d(0,-10px,0);}
    75%  {transform: translate3d(10px,0,0);}
    100% {transform: translate3d(0,0,0);}
}
@-webkit-keyframes myfirst /* Safari 与 Chrome */
{
    0%   {transform: translate3d(0,0,0);}
    25%  {transform: translate3d(-10px,0,0);}
    50%  {transform: translate3d(0,-10px,0);}
    75%  {transform: translate3d(10px,0,0);}
    100% {transform: translate3d(0,0,0);}
}
</style>
